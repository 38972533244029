import React from "react";
import pcParts from '../data/pcParts.json';

export const PCSpecs = ({ parts = pcParts }) => {
  return (
    <div id="pcspecs" className="text-center">
      <div className="container">
        <div className="section-title text-center">
          <h2>PC Specs</h2>
        </div>
        <div className="row">
          {parts.map((part, index) => (
            <div key={index} className="col-md-12 pc-part">
              <div className="row align-items-center">
                <div className="col-md-4 col-sm-4 text-left">
                  <h4>{part.name}</h4>
                </div>
                <div className="col-md-4 col-sm-4 text-center">
                  <img
                    src={part.image}
                    alt={part.name}
                    className="img-responsive"
                    style={{
                      maxHeight: "150px",
                      maxWidth: "100%",
                      display: "block",
                      margin: "0 auto",  // Centers the image horizontally
                    }}
                  />
                </div>
                <div className="col-md-4 col-sm-4 text-right">
                  <a href={part.link} target="_blank" rel="noopener noreferrer" className="btn btn-primary">
                    View Product
                  </a>
                </div>
              </div>
              <hr />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
