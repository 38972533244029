import React from "react";
import { IconContext } from "react-icons";
import { FaDiscord, FaPatreon, FaYoutube } from "react-icons/fa";

export const Patreon = (props) => {
  return (
    <div id="patreon" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>The Pilpoh Server Project</h2>
          <p>
            Become a vital part of our community by joining the Pilpoh Patreon! With your support, we're able to host a diverse array of multiplayer Modded Minecraft worlds, each tailored to different modpacks. Your contributions directly fund the server costs, enabling us to craft immersive experiences for players like you.
          </p>
          <p>
            As a member of our Patreon, you'll gain exclusive access to these multiplayer realms, where you can embark on adventures, collaborate with fellow gamers, and immerse yourself in the endless possibilities of modded Minecraft. So, whether you're a seasoned explorer or a budding architect, consider joining our Patreon today and become an integral part of our ever-expanding Minecraft universe!
          </p>
          <p>
            Click the icon below!
          </p>
        </div>
        <div className="row">
          <IconContext.Provider value={{ color: 'white', size: '100px' }}>
            <a href={"https://www.patreon.com/Pilpoh"} target="_blank" rel="noopener noreferrer">
              <FaPatreon />
            </a>
          </IconContext.Provider>
        </div>
      </div>
    </div>
  );
};
