import React from "react";
import { IconContext } from "react-icons";

export const BuyMeAPickle = (props) => {
  return (
    <div id="buymeapickle" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Buy Me A Pickle</h2>
          <p>
          If you find value in the work I create and it brings you joy or inspiration, please consider showing your support by buying me a pickle! Every small contribution makes a big difference and helps me continue pursuing my passion. Your generosity not only encourages me to keep going but also directly contributes to the growth and quality of the content I provide. Thank you for being a part of this journey and for helping me do what I do every day!
          </p>
          <p>
            Click my pickle below to donate!
          </p>
        </div>
        <div className="row">
          <IconContext.Provider value={{ color: 'white', size: '100px' }}>
            <a href="https://buymeacoffee.com/pilpoh" target="_blank" rel="noopener noreferrer">
              <span role="img" aria-label="pickle" style={{ fontSize: '100px', color: 'white' }}>
                🥒
              </span>
            </a>
          </IconContext.Provider>
        </div>
      </div>
    </div>
  );
};
